var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "k-dialog",
    { attrs: { width: "400", overlay: "false" }, on: { close: _vm.cancel } },
    [
      _c(
        "v-form",
        {
          ref: "newCaseForm",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-container",
            { staticStyle: { padding: "3px" } },
            [
              _c(
                "v-row",
                { staticStyle: { padding: "3px" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { padding: "3px" },
                      attrs: { cols: "12", md: "12" },
                    },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.fieldNames,
                          "item-text": "name",
                          "item-value": "id",
                          label: "Select Field",
                          rules: [
                            function (v) {
                              return !!v || "Field is required"
                            },
                          ],
                        },
                        model: {
                          value: _vm.itemInEdit.filter.column,
                          callback: function ($$v) {
                            _vm.$set(_vm.itemInEdit.filter, "column", $$v)
                          },
                          expression: "itemInEdit.filter.column",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { padding: "3px" },
                      attrs: { cols: "12", md: "12" },
                    },
                    [
                      _c("v-select", {
                        attrs: { items: _vm.operators, label: "Operator" },
                        model: {
                          value: _vm.itemInEdit.filter.operator,
                          callback: function ($$v) {
                            _vm.$set(_vm.itemInEdit.filter, "operator", $$v)
                          },
                          expression: "itemInEdit.filter.operator",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { padding: "3px" },
                      attrs: { cols: "12", md: "12" },
                    },
                    [
                      _c(
                        "v-text-field",
                        _vm._g(
                          {
                            attrs: {
                              label: "Value",
                              rules: [_vm.requiredRule],
                            },
                            model: {
                              value: _vm.itemInEdit.filter.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.itemInEdit.filter, "value", $$v)
                              },
                              expression: "itemInEdit.filter.value",
                            },
                          },
                          _vm.on
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: { padding: "3px" },
                      attrs: { cols: "12", md: "12" },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Filter Group",
                          rules: [_vm.requiredRule],
                        },
                        model: {
                          value: _vm.itemInEdit.filter_group,
                          callback: function ($$v) {
                            _vm.$set(_vm.itemInEdit, "filter_group", $$v)
                          },
                          expression: "itemInEdit.filter_group",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "dialog-actions-bar",
        [
          _c("kbutton", { on: { click: _vm.cancel } }, [_vm._v(" Cancel ")]),
          _c(
            "kbutton",
            { attrs: { "theme-color": "primary" }, on: { click: _vm.save } },
            [_vm._v(" Save ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }