var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dataItem.dynamic
    ? _c("grid", {
        attrs: { "data-items": _vm.sites, columns: _vm.dynColumns },
      })
    : _c("grid", {
        attrs: { "data-items": _vm.dataItem.rpt_sites, columns: _vm.columns },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }