<template>
    <td>
      <v-btn
          class="mx-2"
          icon
          color="green"
          @click="editHandler"
          >
          <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <!-- <button
          class="k-button k-grid-remove-command"
          @click="removeHandler">
          Remove
      </button> -->
    </td>
  </template>
  <script>
  
  export default {
      props: {
          field: String,
          dataItem: Object,
          format: String,
          className: String,
          columnIndex: Number,
          columnsCount: Number,
          rowType: String,
          level: Number,
          expanded: Boolean,
          editor: String
      },
      methods: {
          editHandler: function() {
              this.$emit('edit', {dataItem:this.dataItem});
          },
          removeHandler: function() {
              this.$emit('remove', {dataItem:this.dataItem});
          },
          addUpdateHandler: function() {
              this.$emit('save', {dataItem:this.dataItem});
          },
          cancelDiscardHandler: function() {
              this.$emit('cancel', {dataItem:this.dataItem});
          }
      }
  }
  </script>