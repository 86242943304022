var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "card-shadow border-radius-xl mt-9",
      attrs: { id: "delete" },
    },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "6" } }, [
            _c("div", { staticClass: "px-6 py-6" }, [
              _c(
                "h5",
                { staticClass: "text-h5 font-weight-bold text-typo mb-2" },
                [_vm._v(" Delete Account ")]
              ),
              _c("p", { staticClass: "text-sm text-body font-weight-light" }, [
                _vm._v(
                  " Once you delete your account, there is no going back. Please be certain. "
                ),
              ]),
            ]),
          ]),
          _c("v-col", { staticClass: "my-auto", attrs: { lg: "6" } }, [
            _c("div", { staticClass: "px-6 pt-0" }, [
              _c(
                "div",
                { staticClass: "d-sm-flex align-center ms-auto" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "font-weight-bolder btn-outline-secondary py-5 px-6 ms-auto",
                      attrs: {
                        ripple: false,
                        elevation: "0",
                        color: "#fff",
                        small: "",
                      },
                    },
                    [_vm._v(" Deactivate ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "\n              font-weight-bolder\n              btn-danger\n              bg-gradient-danger\n              py-5\n              px-6\n              ms-2\n            ",
                      attrs: {
                        ripple: false,
                        elevation: "0",
                        color: "#fff",
                        small: "",
                      },
                    },
                    [_vm._v(" Delete Account ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }