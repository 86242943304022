<template>
  <v-card color="white">
    <v-toolbar flat :color="'grey darken-3'">
      <v-toolbar-title style="color: white">
        Edit Dynamic Site Group
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeEditModal()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <Grid
          ref="grid"
          :style="{ height: '300px' }"
          :data-items="result"
          :columns="columns"
          :sortable="true"
          @sortchange="sortChangeHandler"
          :sort="sort"
        >
          <template v-slot:myTemplate="{ props }">
            <custom :data-item="props.dataItem" @edit="edit" @remove="remove" />
          </template>
          <grid-toolbar>
            &nbsp;<v-btn
              title="Add new"
              :theme-color="'primary'"
              @click="insert"
            >
              Add new </v-btn
            >&nbsp;
            <v-btn title="Help" :theme-color="'primary'" @click="openDialog">
              Help
            </v-btn>
            <div class="
              border-radius-md
              mt-2
              ml-4
              mr-4"
            >
              <v-text-field 
              label="Group Name"
              readonly
              v-model="editGroupName"
              >
              </v-text-field>
            </div>
          </grid-toolbar>
          <grid-norecords> There is no data available custom </grid-norecords>
        </Grid>
        <dialog-container
          style="z-index: 9999"
          v-if="itemInEdit"
          :data-item="itemInEdit"
          @save="save"
          @cancel="cancel"
        >
        </dialog-container>
        <v-dialog v-model="helpdialog" max-width="750">
          <v-card>
            <v-card-title class="headline">Help</v-card-title>
            <v-card-text>
              <p>
                For field level filters, each group you create will take all
                filters and "OR" or union them together.
              </p>
              <p>
                For example, if you want sites that are daily or weekly, you
                will create a filter of group 1, that includes field name
                check_frequency = daily, then create another filter item of
                group 1, that includes field name check_frequency = weekly. In
                this first group you will get all the sites that are either
                daily or weekly.
              </p>
              <p>
                For an "AND" operation or to create an intersection, you will
                now create another filter item of group 2, that includes field
                name customer LIKE %Rev%. This will now AND your first group
                (group 1) of daily or weekly with this second group of sites
                that are only of the customer Revision.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :theme-color="'primary'" @click="closeDialog">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import Service from "@/services/Service.js";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import DialogContainer from "./DialogContainer";
import CommandCellv2 from "./CommandCellV2";
import { Button } from "@progress/kendo-vue-buttons";
import { orderBy } from '@progress/kendo-data-query';

import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
Vue.use(VueToast);

export default {
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    "dialog-container": DialogContainer,
    custom: CommandCellv2,
    kbutton: Button,
  },
  props: {
        dataItem: Object,
        groupId: Number,
        name: String,
    },
  data: function () {
    return {
      helpdialog: false,
      gridData: [],
      itemInEdit: null,
      itemInEdit: undefined,
      editGroupName: null,
      columns: [
        { field: "id", editable: false, title: "ID", width: "80px" },
        { field: "filter_group", title: "Filter Group", width: "200px" },
        { field: "filter.column", title: "Field Name", width: "200px"},
        {
          field: "filter.operator",
          title: "Operator",
          filter: "text",
          width: "200px",
          editor: "text",
        },
        { field: "filter.value", title: "Value", width: "200px" },
        { cell: "myTemplate", width: "210px" },
      ],
      sort: [
        { field: 'filter_group', dir: 'asc' }, // Default sorting by name in ascending order
      ],
    };
  },
  computed: {
    result: {
      get: function () {
        return orderBy(this.gridData, this.sort);
      },
    },
  },
  watch: {
    groupId() {
      console.log('id changed');
      this.getData();
    }
  },
  created: function () {
    this.getData();
  },
  methods: {
    getData() {
      console.log(this.dataItem)
      this.previewRptList(this.dataItem.id) 
      this.gridData = this.dataItem.rpt_dynamic_sites;
      this.editGroupName = this.dataItem.name;
    },
    previewRptList(id) {
      var params = {
        site_group_id: id,
      };
      Service.dynSiteGroupQuery(params)
        .then((response) => {
          console.log("success");
          this.showDialog = true;
          this.previewData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      // show dialog
    },
    closeEditModal() {
      this.$emit('closeDynModal', 'success');
    },
    edit(dataItem) {
      //   this.itemInEdit = this.cloneProduct(dataItem);
      this.itemInEdit = this.cloneItem(dataItem);
    },
    remove(dataItem) {
      const params = {
        id: dataItem.id
      };
      Service.deleteRptDynSiteFilter(params)
        .then((response) => {
          console.log("success")
          Vue.$toast.warning(`Site Group removed`, {
            position: "top-right",
            duration: 4000,
          });
          // remove from grid
          this.gridData = this.gridData.filter(
            (p) => p.id !== dataItem.id
          );
        })
        .catch((error) => {
          Vue.$toast.error(`Error removing Site Group`, {
            position: "top-right",
            duration: 4000,
          });
          console.log(error);
        });
    },
    save() {
      const dataItem = this.itemInEdit;
      const items = this.gridData.slice();
      const isNewItem = dataItem.id === undefined;

      if (isNewItem) {
        // create
        const params = {
          rpt_site_group_id: this.dataItem.id,
          id: null,
          filter: {
            column: dataItem.filter.column,
            operator: dataItem.filter.operator,
            value: dataItem.filter.value
          },
          filter_group: dataItem.filter_group
        };
        Service.createRptDynSiteFilter(params)
        .then((response) => {
          console.log("success")
          Vue.$toast.success(`Site Group saved`, {
            position: "top-right",
            duration: 4000,
          });
          dataItem.id = response.data.id;
          const n = this.newItemFromDb(dataItem, response.data.id);
          items.unshift(n);
          // this.getData();
          this.gridData = items;
          this.itemInEdit = undefined;
        })
        .catch((error) => {
          console.log(error);
        });
        
      } else {
        // update
        const params = {
          rpt_site_group_id: this.dataItem.id,
          id: dataItem.id,
          filter: {
            column: dataItem.filter.column,
            operator: dataItem.filter.operator,
            value: dataItem.filter.value
          },
          filter_group: dataItem.filter_group
        };

        Service.createRptDynSiteFilter(params)
        .then((response) => {
          console.log("success")
          Vue.$toast.success(`Site Group saved`, {
            position: "top-right",
            duration: 4000,
          });
          
          
          // update UI
          const index = items.findIndex(
            (p) => p.id === dataItem.id
          );
          items.splice(index, 1, dataItem);
          this.gridData = items;
          this.itemInEdit = undefined;
        })
        .catch((error) => {
          console.log(error);
        });
        
      }

      
    },
    help() {

    },
    openDialog() {
      this.helpdialog = true;
    },
    closeDialog() {
      this.helpdialog = false;
    },
    cancel() {
      this.itemInEdit = undefined;
    },
    insert() {
      // this.itemInEdit = {};
      this.itemInEdit = {
        id: undefined,
        filter: {
          column: '',
          operator: '',
          value: '',
        },
        filter_group: 0
      };
    },
    dialogTitle() {
      // this.itemInEdit.ProductID === undefined ? "Add" : "Edit"
      return `${
        this.itemInEdit.id === undefined ? "Add" : "Edit"
      } `;
    },
    cloneProduct(product) {
      return Object.assign({}, product);
    },
    cloneItem(item) {
      return Object.assign({}, item);
    },
    newItemFromDb(source, id) {
      const newItem = {
        id: id,
        filter: {
          column: '',
          operator: '',
          value: ''
        },
        filter_group: 0
      };

      return Object.assign(newItem, source);
    },
    newItem(source) {
      const id =
        this.gridData.reduce(
          (acc, current) => Math.max(acc, current.id || 0),
          0
        ) + 1;
      const newItem = {
        id: id,
        filter: {
          column: '',
          operator: '',
          value: ''
        },
        filter_group: 0
      };

      return Object.assign(newItem, source);
    },
   
    sortChangeHandler: function (e) {
      this.sort = e.sort;
    },
  },
};
</script>