var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "white" } },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "grey darken-3" } },
        [
          _c("v-toolbar-title", { staticStyle: { color: "white" } }, [
            _vm._v(" Create/Edit Custom Group "),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function ($event) {
                  return _vm.closeEditModal()
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [
              _c(
                "Grid",
                {
                  ref: "grid2",
                  attrs: {
                    "data-items": _vm.result,
                    filterable: true,
                    filter: _vm.filter,
                    "edit-field": "inEdit",
                    take: _vm.take,
                    skip: _vm.skip,
                    total: _vm.total,
                    pageable: _vm.pageable,
                    sortable: { mode: "multiple" },
                    sort: _vm.sort,
                    columns: _vm.siteColumns,
                    "selected-field": _vm.selectedField,
                  },
                  on: {
                    filterchange: _vm.filterChange,
                    sortchange: _vm.sortChange,
                    itemchange: _vm.itemChange,
                    dataStateChange: _vm.dataStateChange,
                    pagechange: _vm.pageChange,
                    selectionchange: _vm.onSelectionChange,
                    headerselectionchange: _vm.onHeaderSelectionChange,
                    rowclick: _vm.onRowClick,
                  },
                },
                [
                  _c("grid-norecords", [
                    _vm._v(" There is no data available custom "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "card-shadow border-radius-xl mr-4" },
                [
                  _c("div", { staticClass: "pb-0 px-4 pt-4" }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "h6",
                        {
                          staticClass:
                            "text-h6 text-typo mb-4 font-weight-bold",
                        },
                        [_vm._v(" New Group ")]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "\n                    border-radius-md\n                    mt-2\n                    ml-4\n                    mr-4",
                              },
                              [
                                _vm.isCreate
                                  ? _c("v-text-field", {
                                      attrs: { label: "Group Name" },
                                      model: {
                                        value: _vm.newGroupName,
                                        callback: function ($$v) {
                                          _vm.newGroupName = $$v
                                        },
                                        expression: "newGroupName",
                                      },
                                    })
                                  : _c("v-text-field", {
                                      attrs: {
                                        label: "Group Name",
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.newGroupName,
                                        callback: function ($$v) {
                                          _vm.newGroupName = $$v
                                        },
                                        expression: "newGroupName",
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "ml-4 mr-4 mt-n2" },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      elevation: "16",
                                      "max-width": "400",
                                    },
                                  },
                                  [
                                    _c("v-virtual-scroll", {
                                      attrs: {
                                        items: _vm.itemsSelected,
                                        height: "300",
                                        "item-height": "64",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "v-list-item",
                                                { key: item.id },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.name) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-divider"),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-xs btn-success bg-gradient-success",
                                  attrs: {
                                    ripple: false,
                                    loading: _vm.loading,
                                    elevation: 0,
                                    id: "create_group",
                                  },
                                  on: { click: _vm.createGroup },
                                },
                                [_vm._v(" Set Group ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }