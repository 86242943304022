var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    { staticClass: "k-command-cell" },
    [
      _c(
        "kbutton",
        {
          staticClass: "k-grid-edit-command",
          attrs: { "theme-color": "primary" },
          on: { click: _vm.editHandler },
        },
        [_vm._v(" Edit ")]
      ),
      _c(
        "kbutton",
        {
          staticClass: "k-grid-remove-command",
          on: { click: _vm.removeHandler },
        },
        [_vm._v(" Remove ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }