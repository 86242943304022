<template>
  <div style="width: 100%">
        <!-- <br />
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          id="upload_sites_btn"
          @click="startCreateGroup"
        >
          Create Group
        </v-btn>
        <v-data-table
          :headers="sgHeaders"
          :items="gridData"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          :items-per-page="10"
          item-key="name"
          show-expand
          class="elevation-1"
          >
          <template v-slot:item.controls="props">
                    <v-btn
                      class="mx-2"
                      icon
                      color="red"
                      @click="deleteSiteGroup(props.item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" style="padding-left: 0px; padding-right: 0px;width: 100%;"><rpt-site-detail v-bind:dataItem="item"></rpt-site-detail></td>
            </template>
          </v-data-table> -->
    <Grid
      ref="gridg"
      :data-items="result"
      :sortable="{ mode: 'multiple' }"
      :sort="sort"
      :filterable="true"
      :filter="filter"
      :pageable="pageable"
      :take="take"
      :skip="skip"
      :page-size="pageSize"
      :total="total"
      @sortchange="sortChangeHandler"
      @filterchange="filterChangeHandler"
      @pagechange="pageChangeHandler"
      :columns="columns"
      :detail="cellTemplate"
      @expandchange="expandChange"
      :expand-field="'expanded'">
      <template v-slot:genTemplate="{ props }">
        <td>
          <v-btn class="mx-2" icon color="green" @click="generateData(props.dataItem)">
              <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </td>
      </template>
      <template v-slot:delTemplate="{ props }">
        <deletecell
          :data-item="props.dataItem"
          @remove="deleteSiteGroup"
        />
      </template>
      <template v-slot:editTemplate="{ props }">
        <editcell 
          :data-item="props.dataItem"
          @edit="editSiteGroup"
        />
      </template>
      <!-- detail -->
      <template v-slot:myTemplate="{props}">
          <sitedetail :data-item="props.dataItem" />
      </template>
      <grid-norecords> There is no data available custom </grid-norecords>
      <grid-toolbar>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          id="upload_sites_btn"
          @click="startChooseModal"
        >
          Create Group
        </v-btn>
      </grid-toolbar>
    </Grid>
    <!-- token dialog -->
    <k-dialog v-if="dialogVisible" width="850px" title="Copy the code below" @close="dialogVisible = false">
      <textarea class="k-textarea" ref="codeTextarea" :value="code" rows="5" readonly style="width: 100%; box-sizing: border-box;"></textarea>
    </k-dialog>
    <!-- create dialog -->
    <k-dialog v-if="chooseModal" width="700px" title="Would you like to create a regular site group or a dynamic site group?" @close="chooseModal = false">
      <v-row>
        <v-col cols="7">
          <v-btn
            :ripple="false"
            :elevation="0"
            class="font-weight-bold text-xs btn-default bg-gradient-default ml-16"
            @click="startCreateGroup"
          >
            Regular Site Group
          </v-btn>
        </v-col>
        <v-col cols="5">
          <v-btn
            :ripple="false"
            :elevation="0"
            class="font-weight-bold text-xs btn-default bg-gradient-default"
            @click="startCreateDynamicGroup"
          >
            Dynamic Site Group
          </v-btn>
        </v-col>
      </v-row>
    </k-dialog>
    <v-dialog v-model="dynamicGroupCreate" width="600">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            Create Dynamic Group
          </span>
        </div>
        <v-card-text class="">
          <v-text-field
            class="ml-3"
            label="Group Name"
            v-model="newDynGroupName"
          >
          </v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn id="dyn_create" :theme-color="'primary'" class="mt-4" @click="createDynamicGroup">Create Group</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCreateGroup" width="1600">
      <EditGroupModal
        :editingSites="editGroupList"
        :groupId="selectedGroupId"
        :isCreate="siteGroupCreate"
        :name="editName"
        @closeModal="closeCreateGroup"
        @reload="getData"
      ></EditGroupModal>
    </v-dialog>
    <v-dialog v-model="showEditDynamicGroup" width="1600">
      <DynamicGroupModal
        :data-item="dynamicDataItem"
        :groupId="selectedGroupId"
        :name="editName"
        @closeDynModal="closeDynamicGroup"
        @reload="getData"
      ></DynamicGroupModal>
    </v-dialog>
    <k-dialog 
      v-if="dialogVisible2" 
      :width="350"
      :height="150"
      :title="'Edit Group Name'"
      @close="dialogVisible2 = false"
      >
      <input v-model="editedText" type="text" class="k-textbox" />
      <v-btn class="ml-4" @click="updateText">Update</v-btn>
    </k-dialog>
  </div>
</template>
  
  <script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { process } from '@progress/kendo-data-query';
import { orderBy } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import CommandCell from "./CommandCell";
import DeleteCell from "./DeleteCell.vue";
import EditCell from "./EditCell.vue";
import { Dialog } from "@progress/kendo-vue-dialogs";
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import EditGroupModal from "./EditGroupModal.vue";
import DynamicGroupModal from "./DynamicGroupModal.vue"
import Service from "@/services/Service.js";
import DeleteAccount from '../Pages/Account/Widgets/DeleteAccount.vue';
import RptSiteDetail from './RptSiteDetail.vue';
Vue.use(VueToast);

export default {
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    custom: CommandCell,
    deletecell: DeleteCell,
    editcell: EditCell,
    "k-dialog": Dialog,
    DeleteAccount,
    EditGroupModal,
    DynamicGroupModal,
    sitedetail: RptSiteDetail,
  },
  props: {
    tick: String,
  },
  watch: {
    tick() {
      console.log('refresh ');
      this.getData();
    }
  },
  data: function () {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      code: '',
      isVisible: true,
      filter: null,
      pageable: { pageSizes: [10, 20, 50, 100] },
      gridData: [],
      skip: 0,
      take: 20,
      random: 1,
      pageSize: 20,
      sortg: null,
      filterg: null,
      selectedGroupId: null,
      skipg: 0,
      takeg: 5,
      editName: null,
      editGroupList: null,
      dynamicDataItem: null,
      chooseModal: false,
      siteGroupCreate: true,
      dynamicGroupCreate: false,
      newDynGroupName: null,
      updatedData: [],
      selectedID: 1,
      selectedField: 'selected',
      cellTemplate: 'myTemplate',
      expandedItems: [],
      showCreateGroup: false,
      showEditDynamicGroup: false,
      editID: null,
      visibleDialog: false,
      valid: true,
      newGroupName: null,
      sgHeaders: [
        { value: 'id', visible: false, text: 'ID', width: '100px' },
        { value: 'name', text: 'Name', width: '150px' },
        { text: "Remove", value: "controls", sortable: false },
      ],
      newSite: {
        name: "",
        customerName: "",
        customerAccountName: "",
      },
      columnDialog: false,
      sort: [{ field: "name", dir: "asc" }],
      selectedSites: [],
    };
  },
  computed: {
    
    result: {
      get: function () {
        return filterBy(this.gridData, this.filter).slice(
          this.skip,
          this.take + this.skip
        );
      },
    },
    itemsSelected() {
      return this.gridData.filter((item) => item.selected)
    },
    length() {
      return 7000;
    },
    inverterDetail() {
      let invDetails = this.expandedInverterDetails;
      return invDetails;
    },
    hasItemsInEdit() {
      return this.gridData.filter((p) => p.inEdit).length > 0;
    },
    total() {
      return this.gridData ? filterBy(this.gridData, this.filter).length : 0;
    },
    user: function () {
      return this.$store.getters.currentUser;
    },
    areAllSelected () {
      const val = this.gridData.findIndex(item => item.selected === false) === -1;
      return val;
    },
    columns: function () {
      return [
        { field: "id", editable: false, title: "ID", width: "150px" },
        { field: "name", width: "275px", cell: this.cellFunction},
        { title: "Edit", cell: 'editTemplate', filterable: false, width: "350px" },
        { title: "Token", cell: 'genTemplate', filterable: false, width: "150px" },
        { title: "Delete", cell: 'delTemplate', filterable: false, width: "350px" },
      ];
    },
  },
  created: function () {
    this.getData();
  },
  mounted() {
    document.title = "Sites";
  },
  methods: {
    generateData(item) {
      this.dialogVisible = true;
      // TODO: make call to generate fleet token url here...
      // http://localhost:4000/rpt/generate_fleet_view_url?site_group_id=33
      Service.genFleetViewUrl(item.id)
        .then((response) => {
          console.log(`generate data ${item}`)
          this.code = response.data.url;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading url`, { position: "top-right" });
          console.log(error);
        });
    },
    startCreateGroup() {
      this.chooseModal = false;
      this.showCreateGroup = true;
      this.siteGroupCreate = true;
      this.selectedGroupId = 0;
    },
    startEditGroup() {
      this.showCreateGroup = true;
      this.siteGroupCreate = false;
    },
    startCreateDynamicGroup() {
      this.chooseModal = false;
      this.showEditDynamicGroup = false;
      this.dynamicGroupCreate = true;
    },
    startEditDynamicGroup() {
      this.showEditDynamicGroup = true;
      this.dynamicGroupCreate = false;
    },
    closeCreateGroup() {
      this.showCreateGroup = false;
    },
    closeDynamicGroup() {
      this.showEditDynamicGroup = false;
    },
    startChooseModal() {
      this.chooseModal = true;
    },
    updateText() {
      // Perform update logic using this.editedText
      console.log('Updated text:', this.editedText);
      console.log(this.selectedItem);
      var params = {
        id: this.selectedItem.id,
        name: this.editedText
      };
      Service.updateDynSiteGroup(params)
        .then((response) => {
          console.log("success")
          Vue.$toast.success(`Site Group updated`, {
            position: "top-right",
            duration: 4000,
          });
          this.getData();
          // Reset the dialog
          this.dialogVisible2 = false;
          this.selectedItem = {};
          this.editedText = '';
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cellFunction: function (h, tdElement, props, listeners) {
      const name = props.dataItem.name;
      const handleClick = () => {
        this.selectedItem = {
          "id": props.dataItem.id,
          "name": name
        };
        this.editedText = name;
        this.dialogVisible2 = true;
      };

      return h(
        "td",
        {
          on: {
            click: handleClick,
          },
        },
        [name]
      );
    },
    createDynamicGroup() {
      var params = {
        name: this.newDynGroupName,
      };
      Service.createDynSiteGroupV2(params)
        .then((response) => {
          console.log("success");
          Vue.$toast.success(`Site Group saved`, {
            position: "top-right",
            duration: 4000,
          });
          this.dynamicGroupCreate = false;
          this.getData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createGroup() {
      // let ids = [102,103,603];
      let ids = this.itemsSelected.map(item => {
        return item.id;
      });
      var params = {
        name: this.newGroupName,
        site_ids: ids,
      };
      Service.createRptSiteGroup(params)
        .then((response) => {
          console.log("success")
          Vue.$toast.success(`Site List created`, { position: "top-right" });
          // close modal and refresh grid
          this.closeCreateGroup();
          this.getData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    expandChange: function (event) {
        // find by id and update
        const idx = this.gridData.findIndex(c => { return c.id === event.dataItem.id});
        this.gridData[idx].expanded = event.value;
    },

    getData: function () {
      NProgress.start();
      // TODO: make sure and get only groups dynamic = false
      Service.getRptSiteGroups()
        .then((response) => {
          // this.gridData = response.data
          // add expanded property for detail view
          this.gridData = response.data.map(item => {
            return {...item, expanded: false};
          });
        })
        .then(() => {
          Service.getDynRptSiteGroups()
            .then((response) => {
              // this.gridData = response.data
              // add expanded property for detail view
              const newItems = response.data.map((item) => ({ ...item, expanded: false }));
              this.gridData = [...this.gridData, ...newItems];
              NProgress.done();
              console.log('check dynamic ones', this.gridData)
            })
        })
        .catch((error) => {
          NProgress.done();
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        })
    },
    // sortChangeHandler(event) {
    //   this.sort = event.sort;
    // },
    filterChangeHandler(event) {
      this.filter = event.filter;
    },
    pageChangeHandler(event) {
      this.take = event.page.take;
      this.skip = event.page.skip;
    },

    onHeaderSelectionChange (event) {
      let checked = event.event.target.checked;
      // only grab items start are in results
      const r = this.result.map((item) => { return {...item, selected: checked} });
      // now set this in gridData

      this.gridData = this.gridData.map((item) => { 
        if(r.find(element => element.id == item.id)) {
          item.selected = checked;
        } 
        return item;
      });
    },
    onSelectionChange (event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    },
    onRowClick (event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      this.gridData = orderBy(this.gridData, this.sort);
    },
    getRandomInt() {
      return Math.floor(Math.random() * 10000);
    },
    dataStateChange: function (event) {
      console.log("dataStateChange...");
    },
    itemChange: function (e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign({}, this.gridData[index], {
          [e.field]: e.value,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        e.dataItem[e.field] = e.value;
      }
    },
    deleteSiteGroup(e) {
      console.log(`delete site group ${e.dataItem.id}`);
      Service.deleteRptSiteGroup(e.dataItem.id)
        .then((response) => {
          Vue.$toast.warning(`Site Group removed`, {
            position: "top-right",
            duration: 4000,
          });
          this.getData();
          // this.update(this.gridData, e.dataItem, true);
        })
        .catch((error) => {
          Vue.$toast.error(`Error removing group`, { position: "top-right" });
          console.log(error);
        });
    },
    editSiteGroup(e) {
      console.log("what is e", e.dataItem.dynamic);
      if (e.dataItem.dynamic == false) {
        let p1 = e.dataItem.name;
        let p2 = Object.assign({}, e.dataItem);
        this.editGroupList = p2;
        this.editName = p1;
        this.selectedGroupId = e.dataItem.id
        this.startEditGroup();
      } else {
        let p1 = e.dataItem.name;
        this.editName = p1;
        this.selectedGroupId = e.dataItem.id
        this.dynamicDataItem = e.dataItem
        console.log(this.dynamicDataItem)
        this.startEditDynamicGroup();
      }

    },
    //new methods for buttons
    getInverterDescription(inverterMetrics, index) {
      return inverterMetrics.description || `inverter ${index + 1}`;
    },
    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
    },
    openWindow: function (link) {
      window.open(link, "_blank");
    },
  },
};
</script>
  
  <style>
.k-toolbar {
  display: block;
}

.k-toolbar #add_new_btn {
  float: left;
}

.k-toolbar #cancel_changes_btn {
  float: left;
  margin-left: 2%;
}

.k-toolbar #upload_sites_btn {
  float: left;
}

.k-toolbar #upload_inverters_btn {
  float: left;
  margin-left: 1%;
}

.k-toolbar #import_hist_btn {
  float: left;
  margin-left: 1%;
}

#create_group {
  float: right;
  margin-right: 2%;
}

.k-grid .k-grid-header .k-header {
  white-space: normal;
  vertical-align: middle;
}
#inverter_link_button {
  float: left;
  margin-left: 5%;
}
/* #details_modal {
    background-color:#f0f2f5;
  } */
#details_div {
  background-color: #f0f2f5;
}
/* #details_container {
    background-color:#f0f2f5;
  } */
#monitoring_link_button {
  float: left;
  margin-left: 5%;
}
#site_plans_button {
  float: left;
  margin-left: 5%;
}
#group_x_btn {
  float: right;
  width: 32px;
}
#dyn_create {
    float: right;
}
.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
</style>