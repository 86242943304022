<template>
    <v-card color="white">
        <v-toolbar flat :color="'grey darken-3'">
        <v-toolbar-title style="color: white"
        >
            Create/Edit Custom Group
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeEditModal()">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        </v-toolbar>
        <v-row>
        <v-col cols="8">
            <Grid
            ref="grid2"
            :data-items="result"
            :filterable="true"
            :filter="filter"
            @filterchange="filterChange"
            :edit-field="'inEdit'"
            :take="take"
            :skip="skip"
            :total="total"
            :pageable="pageable"
            :sortable="{ mode: 'multiple' }"
            :sort="sort"
            @sortchange="sortChange"
            @itemchange="itemChange"
            @dataStateChange="dataStateChange"
            @pagechange="pageChange"
            :columns="siteColumns"
            @selectionchange="onSelectionChange"
            @headerselectionchange="onHeaderSelectionChange"
            @rowclick="onRowClick"
            :selected-field="selectedField"
            >
            <grid-norecords> There is no data available custom </grid-norecords>
            </Grid>
        </v-col>
        <v-col cols="4">
            <v-card class="card-shadow border-radius-xl mr-4">
            <div class="pb-0 px-4 pt-4">
                <div class="d-flex align-items-center">
                <h6 class="text-h6 text-typo mb-4 font-weight-bold">
                    New Group
                </h6>
                </div>
            </div>
            <div>
                <v-row>
                <v-col cols="12">
                    <div class="
                        border-radius-md
                        mt-2
                        ml-4
                        mr-4"
                    >
                        <v-text-field 
                        v-if="isCreate"
                        label="Group Name"
                        v-model="newGroupName"
                        >
                        </v-text-field>
                        <v-text-field 
                        v-else
                        label="Group Name"
                        readonly
                        v-model="newGroupName"
                        >
                        </v-text-field>
                    </div>
                </v-col>
                </v-row>
                <v-row>
                <v-col cols="12">
                    <div class="ml-4 mr-4 mt-n2">
                    <v-card elevation="16" max-width="400" class="mx-auto">
                        <v-virtual-scroll
                        :items="itemsSelected"
                        height="300"
                        item-height="64"
                        >
                        <template v-slot:default="{ item }">
                            <v-list-item :key="item.id">
                            <v-list-item-content>
                                <v-list-item-title>
                                {{ item.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                        </template>
                        </v-virtual-scroll>
                    </v-card>
                    </div>
                </v-col>
                </v-row>
                <v-row>
                <v-col cols="12">
                    <v-btn
                    :ripple="false"
                    :loading=loading
                    :elevation="0"
                    class="font-weight-bold text-xs btn-success bg-gradient-success"
                    id="create_group"
                    @click="createGroup"
                    >
                    Set Group
                    </v-btn>
                </v-col>
                </v-row>
            </div>
            </v-card>
        </v-col>
        </v-row>
    </v-card>
</template>
<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { process } from '@progress/kendo-data-query';
import { orderBy } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import CommandCell from "./CommandCell";
import DeleteCell from "./DeleteCell.vue";
import { Dialog } from "@progress/kendo-vue-dialogs";
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import EditGroupModal from "./EditGroupModal.vue"
import Service from "@/services/Service.js";
import DeleteAccount from '../Pages/Account/Widgets/DeleteAccount.vue';
import RptSiteDetail from './RptSiteDetail.vue';

Vue.use(VueToast);

export default {
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    custom: CommandCell,
    deletecell: DeleteCell,
    "k-dialog": Dialog,
    DeleteAccount,
    EditGroupModal,
    sitedetail: RptSiteDetail,
  },
  props: {
    editingSites: Object,
    groupId: Number,
    isCreate: Boolean,
    name: String,
  },
  data: function () {
    return {
      groupData: [],
      loading: false,
      isVisible: true,
      filter: null,
      sort: [],
      pageable: { pageSizes: [500,1000] },
      gridData: [],
      skip: 0,
      take: 500,
      random: 1,
      pageSize: 500,
      updatedData: [],
      selectedID: 1,
      selectedField: 'selected',
      cellTemplate: 'myTemplate',
      expandedItems: [],
      showCreateGroup: false,
      editID: null,
      visibleDialog: false,
      valid: true,
      newGroupName: null,
      columnDialog: false,
      sort: [{ field: "name", dir: "asc" }],
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true,
      },
    }
  },
  methods: {
    filterChange: function (ev) {
      this.filter = ev.filter;
    },
    sortChange: function (e) {
      this.sort = e.sort;
      this.gridData = orderBy(this.gridData, this.sort);
    },
    filterChangeHandler(event) {
      this.filter = event.filter;
    },
    onHeaderSelectionChange (event) {
      let checked = event.event.target.checked;
      // only grab items start are in results
      const r = this.result.map((item) => { return {...item, selected: checked} });
      // now set this in gridData

      this.gridData = this.gridData.map((item) => { 
        if(r.find(element => element.id == item.id)) {
          item.selected = checked;
        } 
        return item;
      });
    },
    onSelectionChange (event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    },
    onRowClick (event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    },
    getRandomInt() {
      return Math.floor(Math.random() * 10000);
    },
    dataStateChange: function (event) {
      console.log("dataStateChange...");
    },
    pageChange(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    filterChange(event) {
      this.filter = event.filter;
    },
    itemChange: function (e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign({}, this.gridData[index], {
          [e.field]: e.value,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        e.dataItem[e.field] = e.value;
      }
    },
    createGroup() {
      // let ids = [102,103,603];
      let ids = this.itemsSelected.map(item => {
        return item.id;
      });
      // var params = {
      //   name: this.newGroupName,
      //   site_ids: ids,
      // };
      if (this.isCreate) {
        var params = {
          name: this.newGroupName,
          site_ids: ids,
        };
        // loading flag
        this.loading = true;
        Service.createRptSiteGroup(params)
          .then((response) => {
            console.log("success")
            Vue.$toast.success(`Site List created`, { position: "top-right" });
            // close modal and refresh grid
            this.loading = false;
            this.closeEditModal();
            this.$emit('reload', 'success');
          })
          .catch((error) => {
            Vue.$toast.error(`Error Creating Group`, { position: "top-right" });
            this.loading = false;
            console.log(error);
          });
      } else {
        var params = {
          id: this.groupId,
          name: this.newGroupName,
          site_ids: ids,
        };
        this.loading = true;
        Service.updateRptSiteGroup(params)
          .then((response) => {
            console.log("success")
            Vue.$toast.success(`Site List updated`, { position: "top-right" });
            this.loading = false;
            // close modal and refresh grid
            this.closeEditModal();
            this.$emit('reload', 'success');
          })
          .catch((error) => {
            Vue.$toast.error(`Error Updating Group`, { position: "top-right" });
            this.loading = false;
            console.log(error);
          });
      };
    },
    closeEditModal() {
      this.$emit('closeModal', 'success');
    },
    getData: function () {
      NProgress.start();
      Service.getSitesGrid()
        .then((response) => {
          const sites = response.data.map((s) => {
            if (s.exp_date != null) {
              s.exp_date = new Date(s.exp_date);
            }
            return s;
          });
          // products.map(item => { return {...item, selected: false} }),
          if(this.isCreate) {
            // set name to blank if create
            this.newGroupName = "";
            const x = sites.map(item => {
              return {...item, selected: false};
            });
            this.gridData = x;
          } else {
            // otherwise set to what was passed in
            this.newGroupName = this.name;
            const x = sites.map(item => {
              let found = this.editingSites.rpt_sites.find((u) => u.site_id == item.id)
              if(found) {
                return {...item, selected: true};
              } else {
                return {...item, selected: false};
              }
            });
            this.gridData = x;
          }
          
          NProgress.done();
        })
        .catch((error) => {
          NProgress.done();
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        });
    },
  },
  watch: {
    groupId() {
      console.log('id changed');
      this.getData();
    }
  },
  created: function () {
    this.getData();
    console.log(this.editingSites)
  },
  computed: {
    result: {
      get: function () {
        return filterBy(this.gridData, this.filter).slice(
          this.skip,
          this.take + this.skip
        );
      },
    },
    itemsSelected() {
      return this.gridData.filter((item) => item.selected)
    },
    total() {
      return this.gridData ? filterBy(this.gridData, this.filter).length : 0;
    },
    siteColumns: function () {
      return [
        { field: "selected", width: '100px', filterable: false, headerSelectionValue: this.areAllSelected },
        { field: "id", hidden: true, editable: false, title: "ID" },
        { field: "name", width: "175px"},
        { field: "monitoring_system", title: "Monitoring System", width: "175px" },
        { field: "customerAccountName", title: "Account", width: "150px" },
        { field: "secondary_monitoring_system",hidden: false, title: "Secondary Monitor", width: "175px"},
        { field: "check_frequency", title: "Check Frequency", width: "150px"}
      ];
    },
}
}
</script>