var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "Grid",
        {
          ref: "gridg",
          attrs: {
            "data-items": _vm.result,
            sortable: { mode: "multiple" },
            sort: _vm.sort,
            filterable: true,
            filter: _vm.filter,
            pageable: _vm.pageable,
            take: _vm.take,
            skip: _vm.skip,
            "page-size": _vm.pageSize,
            total: _vm.total,
            columns: _vm.columns,
            detail: _vm.cellTemplate,
            "expand-field": "expanded",
          },
          on: {
            sortchange: _vm.sortChangeHandler,
            filterchange: _vm.filterChangeHandler,
            pagechange: _vm.pageChangeHandler,
            expandchange: _vm.expandChange,
          },
          scopedSlots: _vm._u([
            {
              key: "genTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c(
                    "td",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: { icon: "", color: "green" },
                          on: {
                            click: function ($event) {
                              return _vm.generateData(props.dataItem)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-refresh")])],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "delTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("deletecell", {
                    attrs: { "data-item": props.dataItem },
                    on: { remove: _vm.deleteSiteGroup },
                  }),
                ]
              },
            },
            {
              key: "editTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("editcell", {
                    attrs: { "data-item": props.dataItem },
                    on: { edit: _vm.editSiteGroup },
                  }),
                ]
              },
            },
            {
              key: "myTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("sitedetail", { attrs: { "data-item": props.dataItem } }),
                ]
              },
            },
          ]),
        },
        [
          _c("grid-norecords", [_vm._v(" There is no data available custom ")]),
          _c(
            "grid-toolbar",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: {
                    ripple: false,
                    elevation: 0,
                    id: "upload_sites_btn",
                  },
                  on: { click: _vm.startChooseModal },
                },
                [_vm._v(" Create Group ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "k-dialog",
            {
              attrs: { width: "850px", title: "Copy the code below" },
              on: {
                close: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [
              _c("textarea", {
                ref: "codeTextarea",
                staticClass: "k-textarea",
                staticStyle: { width: "100%", "box-sizing": "border-box" },
                attrs: { rows: "5", readonly: "" },
                domProps: { value: _vm.code },
              }),
            ]
          )
        : _vm._e(),
      _vm.chooseModal
        ? _c(
            "k-dialog",
            {
              attrs: {
                width: "700px",
                title:
                  "Would you like to create a regular site group or a dynamic site group?",
              },
              on: {
                close: function ($event) {
                  _vm.chooseModal = false
                },
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "font-weight-bold text-xs btn-default bg-gradient-default ml-16",
                          attrs: { ripple: false, elevation: 0 },
                          on: { click: _vm.startCreateGroup },
                        },
                        [_vm._v(" Regular Site Group ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "font-weight-bold text-xs btn-default bg-gradient-default",
                          attrs: { ripple: false, elevation: 0 },
                          on: { click: _vm.startCreateDynamicGroup },
                        },
                        [_vm._v(" Dynamic Site Group ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.dynamicGroupCreate,
            callback: function ($$v) {
              _vm.dynamicGroupCreate = $$v
            },
            expression: "dynamicGroupCreate",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "card-shadow border-radius-xl" },
            [
              _c("div", { staticClass: "card-header-padding" }, [
                _c(
                  "span",
                  { staticClass: "font-weight-bold text-h5 text-typo mb-0" },
                  [_vm._v(" Create Dynamic Group ")]
                ),
              ]),
              _c(
                "v-card-text",
                {},
                [
                  _c("v-text-field", {
                    staticClass: "ml-3",
                    attrs: { label: "Group Name" },
                    model: {
                      value: _vm.newDynGroupName,
                      callback: function ($$v) {
                        _vm.newDynGroupName = $$v
                      },
                      expression: "newDynGroupName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-4",
                      attrs: { id: "dyn_create", "theme-color": "primary" },
                      on: { click: _vm.createDynamicGroup },
                    },
                    [_vm._v("Create Group")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "1600" },
          model: {
            value: _vm.showCreateGroup,
            callback: function ($$v) {
              _vm.showCreateGroup = $$v
            },
            expression: "showCreateGroup",
          },
        },
        [
          _c("EditGroupModal", {
            attrs: {
              editingSites: _vm.editGroupList,
              groupId: _vm.selectedGroupId,
              isCreate: _vm.siteGroupCreate,
              name: _vm.editName,
            },
            on: { closeModal: _vm.closeCreateGroup, reload: _vm.getData },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "1600" },
          model: {
            value: _vm.showEditDynamicGroup,
            callback: function ($$v) {
              _vm.showEditDynamicGroup = $$v
            },
            expression: "showEditDynamicGroup",
          },
        },
        [
          _c("DynamicGroupModal", {
            attrs: {
              "data-item": _vm.dynamicDataItem,
              groupId: _vm.selectedGroupId,
              name: _vm.editName,
            },
            on: { closeDynModal: _vm.closeDynamicGroup, reload: _vm.getData },
          }),
        ],
        1
      ),
      _vm.dialogVisible2
        ? _c(
            "k-dialog",
            {
              attrs: { width: 350, height: 150, title: "Edit Group Name" },
              on: {
                close: function ($event) {
                  _vm.dialogVisible2 = false
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.editedText,
                    expression: "editedText",
                  },
                ],
                staticClass: "k-textbox",
                attrs: { type: "text" },
                domProps: { value: _vm.editedText },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.editedText = $event.target.value
                  },
                },
              }),
              _c(
                "v-btn",
                { staticClass: "ml-4", on: { click: _vm.updateText } },
                [_vm._v("Update")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }