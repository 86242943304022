<template>
<grid
    v-if="dataItem.dynamic"
   :data-items="sites"
                     :columns="dynColumns"
                     >
</grid>
<grid
    v-else
   :data-items="dataItem.rpt_sites"
                     :columns="columns"
                     >
</grid>
    <!-- <div>
        test
    </div> -->
</template>
<script>
import { Grid } from '@progress/kendo-vue-grid';
import { filterBy } from '@progress/kendo-data-query';
import Service from "@/services/Service.js";

export default {
    components: {
        grid: Grid
    },
    props: {
        dataItem: Object
    },
    created() {
        console.log('created');
        console.log(this.dataItem)
        this.getDynamicList();
    },
    data () {
        return {
            columns: [
                { field: 'site_id', filterable: false, title: 'Site ID', width: '150px' },
                { field: 'name', title: 'Site Name', width: '450px'},
            ],
            dynColumns: [
                { field: 'id', filterable: false, title: 'Site ID', width: '150px' },
                { field: 'name', title: 'Site Name', width: '450px'},
            ],
            sites: [],
        }
    },
    methods: {
        getDynamicList() {
            // debugger;
            Service.getDynSiteList(this.dataItem.id)
                .then((response) => {
                    console.log("this should be it", response.data);
                    this.sites = response.data.site;
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    }
}
</script>